.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(284deg, #fedd4e, #fcb43a);
  color: #fff;
  margin-bottom: 1rem;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title::after {
  content: ' ▸ ';
  padding: 0 0.5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  /*display: flex;*/
  display: grid;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

#searchbox {
  margin-bottom: 2rem;
}

#pagination {
  margin: 2rem auto;
  text-align: center;
}

.hit-name {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
}

.hit-authors {
  margin-top: 3px;
  font-size: 0.8rem;
}

.hit-publication-year {
  font-size: 0.8rem;
  margin-top: 20px;
}

.hit-rating {
  margin-top: 3px;
  font-size: 0.8rem;
}

.stats {
  margin-top: 3px;
  font-size: 0.6rem;
}

/*
.ais-Hits-item {
  padding: 30px;
  box-shadow: none;
  border: 1px solid lighten(lightgray, 8%);
}
*/

.warn {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 1rem;
        background-color: #fff;
        color: #444;
}

.ais-Hits-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 1rem;
        background-color: #fff;
        color: #444;
}

      .ais-Hits-list .ais-Hits-item {
        background: #f2f2f2;
        width: 100%;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      }

      .ais-Hits-list .ais-Hits-item * + * {
        /*padding: 0.5rem; */
        line-height: 1.2;
      }

      .grid-results {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 1rem;
        background-color: #fff;
        color: #444;
      }

      .grid-results .grid-item {
        background: #f2f2f2;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      }
      .grid-results .grid-item * + * {
        padding: 0.5rem;
        line-height: 1.2;
      }
